import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import Img from 'gatsby-image';

import SiteComponent from '../../components/SiteComponent';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import styles from './style.module.css';

import Layout from '../../components/Layout';
import Strip from '../../components/Strip';
import Button from '../../components/Button';
import Translate from '../../components/Translate';

class Services extends SiteComponent {
	constructor(props) {
		super(props, true);
	}

	goToProducts() {
		let slugsServices = [
			{
				slug: 'prodotti',
				polylang_current_lang_code: 'it',
			},
			{
				slug: 'products',
				polylang_current_lang_code: 'en',
			},
		];

		navigate(this.slugger(slugsServices));
	}

	slugger(slugs) {
		return `/${this.props.state.lang}/${slugs
			.map((item) => {
				let slug = '';
				if (item.polylang_current_lang_code === this.props.state.lang) {
					slug = item.slug;
				}
				return slug;
			})
			.join('')}`;
	}

	render() {
		const data = this.props.data;

		let coverKeywords = [];
		let strips = [];
		data.services.edges.forEach((nodeElement, index) => {
			const node = nodeElement.node;
			let listOfKeywords = (
				<ul className={styles.keywordsListNoStyle}>
					{node.acf.keywords ? (
						node.acf.keywords.map((keyword) => {
							return <li key={keyword.keyword_title} dangerouslySetInnerHTML={{ __html: keyword.keyword_title }} />;
						})
					) : (
						<li key={index} />
					)}
				</ul>
			);
			let itemOfCover = (
				<li key={node.title} className={styles.keywordsListContainer}>
					<h3 className={styles.coverKeywordsTitle} dangerouslySetInnerHTML={{ __html: node.title }} />
					{listOfKeywords}
				</li>
			);
			coverKeywords.push(itemOfCover);

			let textTile = (
				<section className={styles.textTile}>
					<h2 dangerouslySetInnerHTML={{ __html: node.title }} />
					<p dangerouslySetInnerHTML={{ __html: node.content }} />
					{listOfKeywords}
				</section>
			);

			let imgTile = (
				<section className={styles.imgTile}>
					{node &&
					node.acf &&
					node.acf.image &&
					node.acf.image.localFile &&
					node.acf.image.localFile.childImageSharp &&
					node.acf.image.localFile.childImageSharp.fluid ? (
						<Img className={styles.serviceImage} fluid={node.acf.image.localFile.childImageSharp.fluid} />
					) : (
						<span>a</span>
					)}
				</section>
			);

			let strip =
				Math.abs(index % 2) === 1 ? (
					<Strip key={`${index}/odd`} className={styles.serviceStripOdd} classNameChildren={styles.serviceStrip}>
						{textTile}
						{imgTile}
					</Strip>
				) : (
					<Strip key={`${index}/even`} className={styles.serviceStriEven} classNameChildren={styles.serviceStrip}>
						{imgTile}
						{textTile}
					</Strip>
				);
			strips.push(strip);
		});

		return (
			<Layout metadata={this.props.data.page.yoast}>
				<Strip className={styles.coverStrip} classNameChildren={styles.coverStripChildren}>
					<h1 className={styles.coverTitle} dangerouslySetInnerHTML={{ __html: this.props.data.page.title }} />
					<p className={styles.coverDescription} dangerouslySetInnerHTML={{ __html: this.props.data.page.content }} />
					<ul className={styles.serviceList}>{coverKeywords}</ul>
				</Strip>
				{strips}
				<Button
					onClickCallback={() => {
						this.goToProducts();
					}}
					className={styles.productsButton}>
					<Translate>servicesPage.goToProducts</Translate>
				</Button>
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Services);

export const pageQuery = graphql`
	query($id: String!, $locale: String!) {
		page: wordpressPage(id: { eq: $id }) {
			id
			slug
			title
			content
			language: polylang_current_lang_code
			polylang_translations {
				id
				slug
				title
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
		}
		services: allWordpressWpServices(
			sort: { fields: [acf___display_order], order: ASC }
			filter: { polylang_current_lang_code: { eq: $locale } }
		) {
			edges {
				node {
					id
					slug
					polylang_current_lang_code
					polylang_translations {
						id
						slug
						polylang_current_lang_code
					}
					title
					content
					template
					acf {
						icon {
							localFile {
								childImageSharp {
									fluid {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
						image {
							localFile {
								childImageSharp {
									fluid {
										sizes
										srcSet
										aspectRatio
										base64
										src
									}
								}
							}
						}
						summary
						keywords {
							keyword_title
						}
						display_order
					}
				}
			}
		}
	}
`;
